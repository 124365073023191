import React, { CSSProperties } from 'react';

import { Leader, WGS } from '@asd-stan/standard/domain/standard-detailed.entity';
import { SystemRoleDTO } from '@asd-stan/user/api/system-role.dto';
import { User } from '@asd-stan/user/domain/user.entity';
import { Avatar } from '@components/avatar/avatar';

import { StyledProjectLeader } from './project-leader.styled';

interface ProjectLeaderProps {
	user: User | Leader | WGS;
}

function styleUserRoles(userRoles: string[]): CSSProperties {
	if (
		!!userRoles.find(
			role =>
				role &&
				(role.toLowerCase() === SystemRoleDTO.ES.toLowerCase() ||
					role.toLowerCase() === SystemRoleDTO.TAC.toLowerCase() ||
					role.toLowerCase() === SystemRoleDTO.MC.toLowerCase())
		)
	) {
		return {
			textTransform: 'uppercase',
		};
	}

	return {
		textTransform: 'capitalize',
	};
}

export const ProjectLeader: React.FC<ProjectLeaderProps> = ({ user }) => {
	const uri = 'picture' in user && user.picture ? user.picture?.path : null;
	const deleted = user.deletedAt !== null;
	const deletedStyle = { opacity: 0.6 };

	return (
		<StyledProjectLeader>
			<Avatar uri={uri} deleted={deleted} />
			<p style={deleted ? deletedStyle : undefined}>
				{user.fullName ? user.fullName : `${user.firstName} ${user.lastName}`}
			</p>
			{user.systemRoles?.map((role, index) => (
				<span
					style={{ ...styleUserRoles(user.systemRoles), ...(deleted && deletedStyle) }}
					key={index}>
					{role}
				</span>
			))}
		</StyledProjectLeader>
	);
};
