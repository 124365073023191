import { UploadedFile } from '@asd-stan/file/domain/file-upload-controller';

export interface MeetingForm {
	domains: { label: string; value: string }[];
	workingGroups: { label: string; value: string }[];
	title: string;
	type: null | { label: string; value: MeetingType };
	startTime: null | Date;
	endTime: null | Date;
	link: string;
	location: string;
	standard: null | { label: string; value: number };
	files: {
		file: UploadedFile;
		description: string;
	}[];
}

export enum MeetingType {
	Remote = 'remote',
	Physical = 'physical',
}

export interface ValidatedMeetingForm {
	domains: { label: string; value: string }[];
	workingGroups: { label: string; value: string }[];
	title: string;
	type: { label: string; value: MeetingType };
	startTime: Date;
	endTime: null | Date;
	link: string;
	location: string;
	standard: null | { label: string; value: number };
	files: {
		file: {
			id: number;
			fileId: number;
			title: string;
			path: string;
			size: number;
		};
		description: string;
	}[];
}

export interface SingleMeeting {
	form: ValidatedMeetingForm;
	filesMeta: {
		id: number;
		createdBy: {
			firstName: string;
			lastName: string;
		};
		createdAt: Date;
	}[];
	standard: null | {
		id: number;
		label: string;
	};
	createdById: number;
}

export interface MeetingListItem {
	id: number;
	domains: { code: string; name: string }[];
	workingGroups: { code: string; name: string }[];
	type: MeetingType;
	title: string;
	startTime: Date;
	location: string;
}

export interface MeetingListFilter {
	search?: null | string;
	orderField?: null | string;
	orderDirection?: 'DESC' | 'ASC';
	domains?: null | {
		[code: string]: boolean;
	};
	workingGroups?: null | {
		[code: string]: boolean;
	};
}
