import { getCurrentUserService } from '@asd-stan/current-user/infrastructure/getters';
import { getDomainService } from '@asd-stan/domain/infrastructure/getters';
import { downloadFileByUrl, getFileByUrl } from '@asd-stan/helpers/download-file-by-url';
import { DomainRole } from '@asd-stan/user/domain/domain-role.enum';
import { SystemRole } from '@asd-stan/user/domain/system-role.entity';

import { getMeetingRepo } from '../infrastructure/getters';

import { MeetingListFilter, ValidatedMeetingForm } from './meeting.entity';

export class MeetingService {
	private get _meetingRepo() {
		return getMeetingRepo();
	}

	private get _currentUserService() {
		return getCurrentUserService();
	}

	private get _domainService() {
		return getDomainService();
	}

	createMeeting(meetingValues: ValidatedMeetingForm) {
		return this._meetingRepo.createMeeting(meetingValues);
	}

	checkIfCurrentUserIsAbleToCreateMeeting() {
		return (
			this._currentUserService.hasRole([SystemRole.DIRECTOR, SystemRole.ES]) ||
			(this._currentUserService.hasRole([SystemRole.EXPERT, SystemRole.MC]) &&
				this._currentUserService.hasWorkingGroupRole(DomainRole.WGS))
		);
	}

	getMeetingList(limit: number, offset: number, filter: MeetingListFilter) {
		return this._meetingRepo.getMeetingList(limit, offset, filter);
	}

	getMeetingListFilter(): MeetingListFilter {
		if (!this._currentUserService.hasOnlyRole(SystemRole.EXPERT)) {
			return {};
		}
		const res = this._currentUserService.domainParticipations.reduce<MeetingListFilter>(
			(acc, { domain, seeAllDomain, workingGroupParticipations }) => {
				if (!acc.domains) {
					acc.domains = {};
				}
				if (!acc.workingGroups) {
					acc.workingGroups = {};
				}
				if (
					seeAllDomain ||
					!this._currentUserService.hasOnlyRoleWithinDomain(DomainRole.EXPERT, domain.code)
				) {
					acc.domains[domain.code] = true;
					Object.assign(
						acc.workingGroups,
						this._domainService
							.getDomainWorkingGroups(domain.code)
							.reduce<{ [code: string]: boolean }>((acc, { code }) => {
								acc[code] = true;
								return acc;
							}, {})
					);
				} else {
					Object.assign(
						acc.workingGroups,
						workingGroupParticipations?.reduce<{ [code: string]: boolean }>((acc, wg) => {
							acc[wg.workingGroup.code] = true;
							return acc;
						}, {})
					);
				}
				return acc;
			},
			{}
		);
		return res;
	}

	getSingleMeeting(id: number) {
		return this._meetingRepo.getSingleMeeting(id);
	}

	async getFile(fileId: number) {
		const url = await this._meetingRepo.getFileDownloadUrl(fileId);
		return getFileByUrl(url);
	}

	async donwloadFile(fileId: number, fileName: string) {
		const url = await this._meetingRepo.getFileDownloadUrl(fileId);
		downloadFileByUrl(url, fileName);
	}

	updateMeeting(id: number, meetingValues: ValidatedMeetingForm) {
		return this._meetingRepo.updateMeeting(id, meetingValues);
	}

	checkIfCurrentUserIsAbleToUpdateMeeting(meetingCreatorId: number) {
		if (this._currentUserService.hasRole([SystemRole.DIRECTOR, SystemRole.ES])) {
			return true;
		}
		if (!this._currentUserService.currentUser) {
			return false;
		}
		return (
			this._currentUserService.hasRole([SystemRole.EXPERT, SystemRole.MC]) &&
			this._currentUserService.hasWorkingGroupRole(DomainRole.WGS) &&
			this._currentUserService.currentUser.id === meetingCreatorId
		);
	}

	deleteMeeting(id: number) {
		return this._meetingRepo.deleteMeeting(id);
	}

	checkIfCurrentUserIsAbleToDeleteMeeting(meetingCreatorId: number) {
		if (this._currentUserService.hasRole([SystemRole.DIRECTOR, SystemRole.ES])) {
			return true;
		}
		if (!this._currentUserService.currentUser) {
			return false;
		}
		return (
			this._currentUserService.hasRole([SystemRole.EXPERT, SystemRole.MC]) &&
			this._currentUserService.hasWorkingGroupRole(DomainRole.WGS) &&
			this._currentUserService.currentUser.id === meetingCreatorId
		);
	}
}
